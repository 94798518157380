const BACKEND_URL = process.env.REACT_APP_API_URL

class APIError extends Error {
    status = 0
    statusText = "";
    responseText = ""
    constructor(status, statusText, responseText) {
        super(statusText)
        this.status = status
        this.statusText = statusText
        this.responseText = responseText
    }
}

const makeReq = async (path, method, params, payload, headers) => {
    let options = {
        method: method,
        mode: 'cors',
        credentials: 'include',
        headers: headers,
        body: (method !== 'GET') ? payload : undefined
    }
    let response = await fetch(`${BACKEND_URL}${path}`, options)
    if (response.status === 401) {
        window.location.replace("/login")
    }
    let contentType = response.headers.get('content-type');
    if (!response.ok) {
        if (contentType && contentType.includes('application/json')) {
            throw new APIError(response.status, response.statusText, await response.json());
        } else {
            throw new APIError(response.status, response.statusText, await response.text());
        }
    } else if (contentType && contentType.includes('application/json')) {
        return response.json()
    } else {
        return response.text()
    }
}

export const FetchClient = (path) => {
    return {
        GET({ params = undefined, headers = { 'Content-Type': 'application/json' } }) {
            return makeReq(path, "GET", params, undefined, headers)
        },
        POST({ params, payload, headers = { 'Content-Type': 'application/json' } }) {
            return makeReq(path, "POST", params, payload, headers)
        },
        PUT({ params, payload, headers = { 'Content-Type': 'application/json' } }) {
            return makeReq(path, "PUT", params, payload, headers)
        },
        PATCH({ params, payload, headers = { 'Content-Type': 'application/json' } }) {
            return makeReq(path, "PATCH", params, payload, headers)
        },
        DELETE({ params, headers = { 'Content-Type': 'application/json' } }) {
            return makeReq(path, "DELETE", params, undefined, headers)
        }
    }
}
