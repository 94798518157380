import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';


const LoadingSkeleton = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {[...Array(7)].map((e, i) =>
                    <Grid item md={6} key={i} >
                        <ListItem className={"list-item"} >
                            <ListItemAvatar>
                                <Skeleton sx={{ marginRight: "20px" }} variant="circular" width={50} height={50} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Skeleton variant="text" sx={{ fontSize: '18px', width: "250px" }} />}
                                secondary={<Skeleton variant="text" sx={{ fontSize: '14px', width: "110px" }} />}
                            />
                        </ListItem>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default LoadingSkeleton