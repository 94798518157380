import React from 'react';
import './New.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Welcome from './pages/Welcome';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';


const theme = createTheme({
  palette: {
    primary: {
      main: "#4a81ff"
    },
    error: {
      main: "#f00"
    }
  },
  status: {
    danger: orange[500],
  },
});


function App() {

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='/home' element={<Home />} />
        <Route path='/' element={<Welcome />} />
        <Route path='/login' element={<Login />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
