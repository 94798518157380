import TopBar from '../components/TopBar';
import CallIcon from "../assets/img/callIcon.svg"
import MessageIcon from "../assets/img/messageIcon.svg"
import { useNavigate } from "react-router-dom";



function Welcome(props) {

    let navigate = useNavigate();

    return (
        <div className='home' >
            <TopBar />
            <div className='home-box'>
                <div className='content'>
                    <h1 className='welcome-title' >Welcome to Codimite !</h1>
                    <p className='welcome-sub-title'>Please select one of the two options below to connect with an employee in this building</p>
                    <div className='welcome'>
                        <div onClick={() => navigate("/home?service=call")} className='item call'>
                            <img src={CallIcon} alt={"call"} style={{ height: "90px", marginRight: "20px" }} />
                            <h3>Make a call</h3>
                        </div>
                        <div onClick={() => navigate("/home?service=message")} className='item msg'>
                            <img src={MessageIcon} alt={"call"} style={{ height: "60px", marginRight: "40px" }} />
                            <h3>Send a Message</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Welcome;
