import UserImg from "../../src/assets/img/user-new.png"
import ZoomLogo from "../assets/img/Zoom-Logo.png"
const BACKEND_URL = process.env.REACT_APP_API_URL


function Login() {

    const loginOnClick = () => {
        window.location.replace(BACKEND_URL + "/zoom/login")
    }

    return (
        <div className="login">
            <div className="top">
                <img src={ZoomLogo} alt="zoom" />
                <div className="divider"></div>
                <h3>Virtual Receptionist</h3>
            </div>
            <div className="login-box">
                <div className="right">
                    <img src={UserImg} alt="img" />
                </div>
                <div className="left">
                    <h4>Virtual Receptionist</h4>
                    <small>
                        Virtual receptionist will allow you to directly connect your visitors with any employee in your organization.
                    </small>
                    <ul>
                        <li>• View all the employees in your location in virtual receptionist.</li>
                        <li>• Filter employees based on their departments.</li>
                        <li>• Easily search for employees within a building</li>
                        <li>• Join audio or video meetings with visitors.</li>
                    </ul>
                    <div onClick={loginOnClick} className="login-button" >Login With Zoom</div>
                </div>
            </div>
        </div>
    )
}


export default Login