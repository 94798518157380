import zoomLogo from "../assets/img/Zoom-Logo.png"
import moment from "moment"
import { Logout } from "../services/auth"
import { useEffect } from "react"



const TopBar = () => {

    const logoutOnClick = async () => {
        await Logout()
        window.location.replace("/login")
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // useEffect(() => {
    //     let at = getCookie("at")
    //     let rt = getCookie("rt")
    //     if (!at && !rt) {
    //         window.location.replace("/login")
    //     }
    // }, [])

    return (
        <div className='top-bar'>
            <div className='left'>
                <img src={zoomLogo} alt={"zoom"} />
                <div className='divider'></div>
                <h3>Virtual Receptionist</h3>
            </div>
            <div className='right'>
                <p>{moment().format('MMMM Do, YYYY hh:mm A')}</p>
                <small onClick={logoutOnClick} style={{ color: "#4a81ff", fontWeight: "bold", cursor: "pointer" }}>Logout</small>
            </div>
        </div>
    )
}

export default TopBar