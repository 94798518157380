import React from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import { useEffect, useState } from "react"
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';

import TopBar from '../components/TopBar';

import { getAllUsers } from "../services/users"
import { NotifyUser } from "../services/meeting"
import { SendMessage } from "../services/message"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import LoadingSkeleton from "../components/LoadingSkeleton"
import UsrLogo from "../assets/img/user-logo.png"


const KJUR = require('jsrsasign')

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.7/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');


function CallDialog(props) {

    const [err, setErr] = useState(false)
    const [fullName, setFullName] = useState("")
    const [loading, setLoading] = useState(false)

    var sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY
    var role = 0
    var leaveUrl = `${process.env.REACT_APP_BASE_URL}`
    var userEmail = ''
    var passWord = ''
    var registrantToken = ''

    const closeDialog = () => {
        setFullName("")
        setErr(false)
        props.handleDialogClose()
    }

    async function startMeeting(user) {
        if (fullName === "") {
            setErr(true)
        } else {
            setLoading(true)
            try {
                const meeting = await NotifyUser(user.id, fullName)
                getSignature(meeting.meeting_Id, meeting.password,fullName)
            } catch (error) {
                console.log(err)
            } finally {
                setLoading(false)
                closeDialog()
                setFullName("")
            }
        }
    }

    const fullNameOnChange = (e) => {
        setErr(false)
        setFullName(e.target.value)
    }

    function getSignature(meetingNumber, password, fullName) {
        const iat = Math.round(new Date().getTime() / 1000) - 30;
        const exp = iat + 60 * 60 * 2
        const oHeader = { alg: 'HS256', typ: 'JWT' }
        const oPayload = {
            sdkKey: sdkKey,
            mn: meetingNumber,
            role: role,
            iat: iat,
            exp: exp,
            appKey: sdkKey,
            tokenExp: iat + 60 * 60 * 2
        }
        const sHeader = JSON.stringify(oHeader)
        const sPayload = JSON.stringify(oPayload)
        const signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, process.env.REACT_APP_ZOOM_SDK_SECRET)
        startMeet(signature, meetingNumber, fullName, password)
    }

    function startMeet(signature, meetingNumber, fullName, pass) {
        document.getElementById('zmmtg-root').style.display = 'block'
        ZoomMtg.init({
            leaveUrl: leaveUrl,
            success: (success) => {
                console.log(success)
                ZoomMtg.join({
                    signature: signature,
                    meetingNumber: meetingNumber,
                    userName: fullName,
                    sdkKey: sdkKey,
                    userEmail: userEmail,
                    passWord: pass,
                    tk: registrantToken,
                    success: (success) => {
                        console.log(success)
                    },
                    error: (error) => {
                        console.log(error)
                    }
                })
            },
            error: (error) => {
                console.log(error)
            }
        })
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={closeDialog}
            >
                <DialogContent className='dialog-content'>

                    <IconButton onClick={closeDialog} size='large' className='close-btn'><CloseIcon /></IconButton>

                    <div className='user'>
                        <img referrerPolicy="no-referrer" className='img' alt='user' src={props.user?.pic_url} />
                        <div className='details'>
                            <p>{props.user?.first_name} {props.user?.last_name}</p>
                            <small>{props.user?.email}</small>
                        </div>
                    </div>

                    <FormControl error={err} sx={{ marginTop: "40px", width: '100%', marginBottom: "40px" }} variant="outlined">
                        <InputLabel htmlFor="name">Enter Your Name</InputLabel>
                        <OutlinedInput
                            className='input'
                            sx={{ height: "55px" }}
                            id="name"
                            type={'other'}
                            label="Enter Your Name"
                            value={fullName}
                            onChange={(e) => fullNameOnChange(e)}
                        />
                        {err ? <small style={{ marginTop: "10px", color: "red" }}>Please enter your name</small> : <></>}
                    </FormControl>

                    <DialogActions>
                        <Button onClick={closeDialog} style={{ marginRight: "5px" }} className='cus-btn-secondary' size='large'>Cancel</Button>
                        <Button disabled={loading} onClick={() => startMeeting(props.user)} className='cus-btn-primary' size='large' autoFocus variant='contained'>
                            {loading ? <CircularProgress style={{ color: "white", height: "30px", width: "30px" }} /> : "Call"}
                        </Button>
                    </DialogActions>

                </DialogContent>
            </Dialog>
        </div>
    );
}

function MessageDialog(props) {

    const [nameErr, setNameErr] = useState(false)
    const [msgErr, setMsgErr] = useState(false)
    const [fullName, setFullName] = useState("")
    const [msg, setMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const closeDialog = () => {
        setFullName("")
        setMsg("")
        setNameErr(false)
        setMsgErr(false)
        props.handleDialogClose()
    }

    const fullNameOnChange = (e) => {
        setNameErr(false)
        setFullName(e.target.value)
    }

    const msgOnChange = (e) => {
        setMsgErr(false)
        setMsg(e.target.value)
    }

    const sendMessage = async () => {
        if (fullName === "")
            setNameErr(true)
        if (msg === "")
            setMsgErr(true)
        if (msg !== "" && fullName !== "") {
            setLoading(true)
            try {
                await SendMessage(props.user?.id, { text: msg, full_name: fullName })
                toast.success("Message sent successfully", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "light",
                })
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
                closeDialog()
            }
        }
    }

    return (
        <div>
            <ToastContainer />
            <Dialog
                open={props.open}
                onClose={closeDialog}
            >
                <DialogContent className='dialog-content'>

                    <IconButton onClick={closeDialog} size='large' className='close-btn'><CloseIcon /></IconButton>

                    <div className='user'>
                        <img referrerPolicy="no-referrer" className='img' alt='user' src={props.user?.pic_url} />
                        <div className='details'>
                            <p>{props.user?.first_name} {props.user?.last_name}</p>
                            <small>{props.user?.email}</small>
                        </div>
                    </div>

                    <FormControl error={nameErr} sx={{ marginTop: "40px", width: '100%', marginBottom: "20px" }} variant="outlined">
                        <InputLabel htmlFor="name">Enter Your Name</InputLabel>
                        <OutlinedInput
                            className='input'
                            sx={{ height: "55px" }}
                            id="name"
                            type={'other'}
                            label="Enter Your Name"
                            value={fullName}
                            onChange={(e) => fullNameOnChange(e)}
                        />
                        {nameErr ? <small style={{ marginTop: "10px", color: "red" }}>Please enter your name</small> : <></>}
                    </FormControl>

                    <FormControl error={msgErr} sx={{ marginTop: "0px", width: '100%', marginBottom: "40px" }} variant="outlined">
                        <InputLabel htmlFor="msg">Type Your Message</InputLabel>
                        <OutlinedInput
                            className='input'
                            id="msg"
                            type={'other'}
                            label="Type Your Message"
                            value={msg}
                            onChange={(e) => msgOnChange(e)}
                            multiline
                            rows={5}
                            maxRows={6}
                        />
                        {msgErr ? <small style={{ marginTop: "10px", color: "red" }}>Please Type your Message</small> : <></>}
                    </FormControl>

                    <DialogActions>
                        <Button onClick={closeDialog} style={{ marginRight: "5px" }} className='cus-btn-secondary' size='large'>Cancel</Button>
                        <Button disabled={loading} onClick={sendMessage} className='cus-btn-primary' size='large' autoFocus variant='contained'>
                            {loading ? <CircularProgress style={{ color: "white", height: "30px", width: "30px" }} /> : "Send"}
                        </Button>
                    </DialogActions>

                </DialogContent>
            </Dialog>
        </div>
    );
}

function Home() {

    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [departments, setDepartments] = useState([])
    const [department, setDepartment] = useState(0)
    const [callDialogOpen, setCallDialogOpen] = useState(false);
    const [msgDialogOpen, setMsgDialogOpen] = useState(false);
    const [user, setUser] = useState(null)

    const [searchParams] = useSearchParams();
    const service = searchParams.get("service")

    const getUsers = async () => {
        try {
            const data = await getAllUsers()
            setUsers(data?.users)
            data?.users.forEach((user) => {
                if (user?.dept) {
                    setDepartments(curr => {
                        if (!curr.includes(user.dept)) {
                            return [...curr, user.dept]
                        }
                        else {
                            return [...curr]
                        }
                    })
                }
            });
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    function handleSearch() {
        return users.filter(user => {
            if (department) {
                if ((user.first_name.toLowerCase().includes(search.toLowerCase()) || user.last_name.toLowerCase().includes(search.toLowerCase())) && user.dept === department) {
                    return user
                } else {
                    return null
                }
            } else {
                if (user.first_name.toLowerCase().includes(search.toLowerCase()) || user.last_name.toLowerCase().includes(search.toLowerCase())) {
                    return user
                } else {
                    return null
                }
            }
        })
    }

    const handleDialogOpen = (user) => {
        if (service === "call")
            handleCallDialogOpen(user)
        else if (service === "message")
            handleMsgDialogOpen(user)
    }

    const handleCallDialogOpen = (user) => {
        setUser(user)
        setCallDialogOpen(true)
    }

    const callDialogClose = () => {
        setUser(null)
        setCallDialogOpen(false)
    }

    const handleMsgDialogOpen = (user) => {
        setUser(user)
        setMsgDialogOpen(true)
    }

    const msgDialogClose = () => {
        setUser(null)
        setMsgDialogOpen(false)
    }

    useEffect(() => {
        getUsers()
    }, [])

    let navigate = useNavigate();

    return (
        <div className='home' >
            <TopBar />
            <div className='home-box'>
                <div className='title-bar'>
                    <div onClick={() => navigate("/")} className='back-btn'> <FontAwesomeIcon className='icon' icon={faChevronLeft} />Go Back</div>
                    <h3>Select an employee to {service === "call" ? "make a call" : "send a message"}</h3>
                    <div>

                        <FormControl sx={{ m: 1, width: '30ch' }}>
                            <InputLabel id="demo-simple-select-label">Department</InputLabel>
                            <Select
                                sx={{ height: "55px" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={department}
                                label="Department"
                                onChange={(e) => setDepartment(e.target.value)}
                            >
                                <MenuItem value={0}>All Departments</MenuItem>
                                {departments.map((d, i) => (
                                    <MenuItem key={i} value={d}>{d}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, width: '30ch', }} variant="outlined">
                            <InputLabel htmlFor="search-user">Search</InputLabel>
                            <OutlinedInput
                                sx={{ height: "55px" }}
                                id="search-user"
                                type={'text'}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                label="Search"
                            />
                        </FormControl>

                    </div>
                </div>
                <div className='content'>
                    {
                        !loading ?
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    {
                                        handleSearch().map((user, i) => (
                                            <Grid item md={6} key={i}>
                                                <ListItem onClick={() => handleDialogOpen(user)} className={"list-item"} >
                                                    <ListItemAvatar>
                                                        <img className="list-img" referrerPolicy="no-referrer" alt={`${user.first_name} ${user.last_name}`} src={user?.pic_url ? user?.pic_url : UsrLogo} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={<strong>{user.first_name} {user.last_name}</strong>}
                                                        secondary={user.email}
                                                    />
                                                </ListItem>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Box> :
                            <LoadingSkeleton />
                    }
                </div>
            </div>
            <CallDialog open={callDialogOpen} user={user} handleDialogClose={callDialogClose} />
            <MessageDialog open={msgDialogOpen} user={user} handleDialogClose={msgDialogClose} />
        </div>
    );
}

export default Home;
